import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const GameCard = ({ title, image, description, links }) => {
    const styles = {
        card: {
            backgroundColor: "#1113",
            borderRadius: "7px",
            color: "#fff",
            border: "none",
            maxWidth: "350px",
            margin: "0 auto",
            padding: "20px",
            textAlign: "center",
            boxShadow: "0px 0px 15px 3px #fff1",
        },
        image: {
            width: "auto",
            height: "200px",
            objectFit: "contain",
            boxShadow: "0px 0px 15px 3px #fff1",
            borderRadius: "7px",
        },
        body: {
            padding: "0px",
        },
        title: {
            fontSize: "1.75rem", // Smaller font size for the title
            marginTop: "20px",
        },
        text: {
            fontSize: "1rem", // Smaller font size for the description
            textAlign: "left",
        },
        linkContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        link: {
            color: "#008EC3",
            textDecoration: "none",
            display: "inline-block", // Display as inline-block to fit text width

            position: "relative",
        },
        linkUnderline: {
            content: '""',
            position: "absolute",
            left: 0,
            bottom: -2, // Adjust as needed
            height: "1px",
            width: "100%",
            backgroundColor: "#008EC355",
            transition: "width 0.3s",
            width: "100%",
        },
    };

    return (
        <Col xs='12' md='6' lg='4' style={{ marginBottom: "75px" }}>
            <Container>
                <div style={styles.card}>
                    <img
                        src={image}
                        alt={`${title} Art`}
                        style={styles.image}
                    />
                    <div style={styles.body}>
                        <h2 style={styles.title}>{title}</h2>
                        <p style={styles.text}>{description}</p>
                        <div style={styles.linkContainer}>
                            {links.map((link, index) => (
                                <div key={index} style={styles.text}>
                                    <a
                                        href={link[1]}
                                        style={styles.link}
                                        rel='noreferrer'
                                        target='_blank'
                                    >
                                        {link[0]}
                                        <div
                                            className='underline'
                                            style={styles.linkUnderline}
                                        ></div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Container>
        </Col>
    );
};

export default GameCard;
