import "./styles/App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGamepad } from "@fortawesome/free-solid-svg-icons";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faTiktok } from "@fortawesome/free-brands-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import logo from "./images/Logo.png";

import {
    Card,
    CardBody,
    CardHeader,
    CardText,
    CardTitle,
    Container,
    Col,
    Row,
    Navbar,
    Nav,
} from "react-bootstrap";

import GameCard from "./components/GameCard";

function App() {
    return (
        <div>
            <div className='App'>
                <Navbar
                    style={{
                        backgroundColor: "#131416",
                        minHeight: "100px",
                        width: "100%",
                    }}
                    expand='lg'
                    variant='dark'
                >
                    <Container>
                        <Navbar.Brand
                            href='#home'
                            style={{ color: "white", marginRight: "-150px" }}
                        >
                            <Row>
                                <Col xs='6' style={{ padding: "0" }}>
                                    <img
                                        src={logo}
                                        alt='Logo'
                                        width={75}
                                        height={75}
                                    />{" "}
                                </Col>
                                <Col
                                    xs='6'
                                    style={{
                                        textAlign: "left",
                                        alignContent: "center",
                                        padding: "0",
                                    }}
                                >
                                    <Col xs='12'>HyperQuest</Col>
                                    <Col xs='12'>Games</Col>
                                </Col>
                            </Row>
                        </Navbar.Brand>
                        {/* <Navbar.Toggle aria-controls='basic-navbar-nav' /> */}
                        <Navbar.Collapse id='basic-navbar-nav'>
                            <Nav className='ml-auto'>
                                {/* <Nav.Link href='#home'>HOME</Nav.Link> */}
                                {/* <Nav.Link href='#steam'>STEAM</Nav.Link> */}
                                {/* <Nav.Link href='#twitter'>TWITTER</Nav.Link> */}
                                {/* <Nav.Link href='#discord'>DISCORD</Nav.Link> */}
                                {/* <Nav.Link href='#contact'>CONTACT</Nav.Link> */}
                                {/* <Nav.Link href='#other-games'>GAMES</Nav.Link> */}
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>

                {/* <FontAwesomeIcon icon={faGamepad} /> */}
                <br />
                <Row style={{ fontSize: "calc(1.25rem + 1.5vw)" }}>
                    <Col>
                        <a
                            className='social-icon'
                            target='_blank'
                            rel='noreferrer'
                            href='https://twitter.com/Hyperquest2515'
                        >
                            <FontAwesomeIcon icon={faXTwitter} />
                            {"  "}
                        </a>
                        <a
                            className='social-icon'
                            target='_blank'
                            rel='noreferrer'
                            href='https://www.youtube.com/channel/UC7YjEqe-WEzLk7nc5DAmyTw'
                        >
                            <FontAwesomeIcon icon={faYoutube} />{" "}
                        </a>
                        <a
                            className='social-icon'
                            target='_blank'
                            rel='noreferrer'
                            href='https://www.tiktok.com/@hyperquestgames'
                        >
                            <FontAwesomeIcon icon={faTiktok} />{" "}
                        </a>
                        <a
                            className='social-icon'
                            target='_blank'
                            rel='noreferrer'
                            href='https://www.facebook.com/profile.php?id=61551895658757'
                        >
                            <FontAwesomeIcon icon={faFacebook} />
                        </a>
                    </Col>
                </Row>
                <Row
                    style={{
                        marginTop: "50px",
                        width: "100%",
                        justifyContent: "center",
                    }}
                >
                    <GameCard
                        title='RIFT DRIFT'
                        image='./images/Rift Drift Itch Cover.png'
                        description='Rift drift is a procedurally generated Infinite Drifter.'
                        links={[
                            [
                                "Play The Prototype",
                                "https://hyperquest-games.itch.io/rift-drift-prototype",
                            ],
                        ]}
                    />
                    <GameCard
                        title='SPINNER'
                        image='./images/Spinner Thumbnail.png'
                        description='Spinner is a one touch arcade mobile game. Test your reflexes and set a new highscore.'
                        links={[
                            [
                                "Download on Google Play",
                                "https://play.google.com/store/apps/details?id=com.HyperQuestGames.Spinner",
                            ],
                        ]}
                    />
                </Row>
            </div>
        </div>
    );
}

export default App;
